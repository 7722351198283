/** @deprecated */
type TODO_UNKNOWN_TYPE = any

interface State {
  stripe: boolean
  cart: {
    combinedSuppliers: TODO_UNKNOWN_TYPE[]
  }
  registration: {
    clinicChains: TODO_UNKNOWN_TYPE[]
  }
  possibleFeatureKeys: TODO_UNKNOWN_TYPE[]
  marketing: {
    campaigns: TODO_UNKNOWN_TYPE[]
  }
  clinicChain: TODO_UNKNOWN_TYPE
}

/** @deprecated Use {@link useFeatureFlagsStore} instead */
export const useFeatureSettingsStore = defineStore('feature-settings', {
  // convert to a function
  state: (): State => ({
    stripe: false,
    cart: { combinedSuppliers: [] },
    registration: {
      clinicChains: [],
    },
    possibleFeatureKeys: [],
    marketing: { campaigns: [] },
    clinicChain: {},
  }),
  actions: {
    /** @deprecated Use {@link loadFeatureFlags()} instead */
    async loadSettings() {
      const { data } = await useFetch<State>('/api/feature-settings/')
      if (data.value) {
        this.$patch((state: State) => {
          for (const key of Object.keys(data.value!) as Array<keyof State>)
            state[key] = data.value![key]
        })
      }
    },
    // easily reset state using `$reset`
    reset() {
      this.$reset()
    },
  },
})
