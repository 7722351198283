import moment from 'moment'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      moment(...params: any) {
        return moment(...params)
      },
    },
  }
})
