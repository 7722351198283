<script setup>
import IconMagnify from '~icons/mdi/magnify'

const display = useDisplay()
const userStore = useUserStore()
const searchStore = useSearchStore()
const route = useRoute()
const { countryLocalePath } = useCountry()

const searchRef = ref()
const searchModel = ref()
const isMenuOpen = ref(false)
watch(() => route.path, (value) => {
  if (value === countryLocalePath('/search'))
    searchModel.value = route.query.q
  else searchModel.value = null
}, { immediate: true })

searchStore.getSearchHistory()

function onUpdateSearch(query) {
  // Somehow searchStore.data the return of the CS Api is sometimes a html string - no idea why. So this prevent the .find() of undefined
  if (!searchStore.data)
    return
  const MIN_SEARCH_LENGTH = 3
  if (
    !searchStore.data.suggestions.find(item => item.value.includes(query))
  ) {
    // MIN_SEARCH_LENGTH validation is set by webshop api
    if (query && query.trim().length >= MIN_SEARCH_LENGTH)
      searchStore.autocomplete(query).then(() => isMenuOpen.value = true)
  }
}
function onChange(event) {
  if (!event)
    return
  // If change value is an object it's a click on a menu list item
  if (typeof event === 'object' && event.value) {
    navigateTo({ path: countryLocalePath('search'), query: { q: event.value } })
    searchRef.value.blur()
  }
}
function onFocused(event) {
  if (!event) {
    userStore.mobileSearchToggle = false
    return
  }

  userStore.mobileSearchToggle = true
}
function onKeyupEnter() {
  if (!searchModel.value)
    return
  const searchString = typeof searchModel.value === 'object' ? searchModel.value.value : searchModel.value
  navigateTo({ path: countryLocalePath('search'), query: { q: searchString } })
  searchRef.value.blur()
}
</script>

<template>
  <v-combobox
    id="search-input"
    ref="searchRef"
    v-model="searchModel"
    data-test-id="search-bar-input"
    :menu-props="{
      maxWidth: '100%',
    }"
    bg-color="white"
    color="black"
    :menu="isMenuOpen"
    transition="slide-y-transition"
    class="search-bar mx-4 my-0"
    :items="searchStore.data.suggestions"
    density="comfortable"
    variant="solo"
    auto-select-first="exact"
    clearable
    hide-details
    rounded="xl"
    :placeholder="display.smAndDown.value && !userStore.mobileSearchToggle ? $t('layout.search.placeholder.short') : $t('layout.search.placeholder.full')"
    :prepend-inner-icon="IconMagnify"
    item-title="label"
    @click:clear="searchStore.resetToHistory()"
    @update:search="onUpdateSearch"
    @update:focused="onFocused"
    @update:model-value="onChange"
    @keyup.enter="onKeyupEnter"
  >
    <template #append>
      <v-btn
        v-if="display.smAndUp.value"
        id="search-button"
        data-test-id="search-button"
        rounded="xl"
        color="white"
        variant="outlined"
        elevation="2"
        height="48px"
        class="ms-1 px-6 bg-green-500"
        @click="onKeyupEnter"
      >
        {{ $t('layout.search.placeholder.short') }}
      </v-btn>
    </template>
  </v-combobox>
</template>

<style lang="scss">
.search-bar {

  .v-icon {
    opacity: .33;
    color: rgb(var(--v-theme-black-800))
  }

  .v-field--focused {
    color: rgb(var(--v-theme-black-800)) !important;
    .v-icon {
      opacity: .7;
      color: rgb(var(--v-theme-black-800));
    }
  }
  .v-field--variant-solo.v-field--focused .v-field__overlay{
    background-color: white;
    color: rgba(0,0,0,.87) !important;
  }
}
div.v-input__append {
  margin:0 !important;
}
</style>
