export const heilandVariables = {
  // 'border-color': '#000000',
  // 'border-opacity': 0.12,
  // 'high-emphasis-opacity': 0.87,
  // 'medium-emphasis-opacity': 0.60,
  // 'disabled-opacity': 0.38,
  // 'idle-opacity': 0.04,
  // 'hover-opacity': 0.04,
  // 'focus-opacity': 0.12,
  // 'selected-opacity': 0.08,
  // 'activated-opacity': 0.12,
  // 'pressed-opacity': 0.12,
  // 'dragged-opacity': 0.08,
  // 'theme-kbd': '#212529',
  // 'theme-on-kbd': '#FFFFFF',
  // 'theme-code': '#F5F5F5',
  // 'theme-on-code': '#000000',
  'font-family': 'helvetica',
}
