import type { SearchSuggestion } from '~/types'

interface SuggestionDto {
  suggestions: Array<SearchSuggestion>
}
interface State {
  data: SuggestionDto
  history: SuggestionDto
  query: string | null
  isLoading: boolean
}

export const useSearchStore = defineStore('search', {
  // convert to a function
  state: (): State => ({
    data: {
      suggestions: [],
    },
    history: {
      suggestions: [],
    },
    query: null,
    isLoading: false,
  }),
  getters: {
  },
  actions: {
    resetToHistory() {
      this.data.suggestions = this.history.suggestions
    },
    async getSearchHistory() {
      const { $i18n, $storeErrorLog } = useNuxtApp()
      this.isLoading = true

      try {
        const { data, error } = await useFetch<SuggestionDto>('/api/searchHistory')
        if (error.value)
          throw error.value
        if (data.value == null)
          throw new Error('Faile to load search-history')

        this.data = data.value
        this.query = null
        this.isLoading = false
        this.history = data.value
      }
      catch (error: any) {
        $storeErrorLog('stores/search.ts', 'Could not load search history', $i18n.t('errors.page.unknown.subtitle'), error)
      }
    },
    async autocomplete(query: string) {
      const { $i18n, $storeErrorLog } = useNuxtApp()
      // Items have already been requested
      if (this.isLoading)
        return

      this.isLoading = true
      try {
        const { data, error } = await useFetch<SuggestionDto>(`/api/v2/findSuggestions?q=${query}`)
        if (error.value)
          throw error.value
        if (data.value == null)
          throw new Error('Failed to find suggestions')

        this.data.suggestions = data.value.suggestions
        this.query = query
      }
      catch (error: any) {
        let snackbarMessage = $i18n.t('errors.page.unknown.subtitle')
        if (error.data && error.data.data)
          snackbarMessage = error.data.data
        $storeErrorLog('stores/search.ts', `Could not load autocomplete by query: ${query}`, snackbarMessage, error)
      }

      this.isLoading = false
    },

    // easily reset state using `$reset`
    reset() {
      this.$reset()
    },
  },
})
