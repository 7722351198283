<script setup lang="ts">
const props = defineProps<{
  error: any
}>()
const { t } = useI18n()
const { $log } = useNuxtApp()
const config = useRuntimeConfig()
const localePath = useLocalePath()

const showError = config.public.environment !== 'prod'
const { countryLocalePath } = useCountry()

const backToHome = () => clearError && navigateTo(localePath('/'))
const title = ref()
const subtitle = ref()
watch(
  () => props.error.statusCode,
  (statusCode) => {
    if (statusCode === 404) {
      navigateTo(countryLocalePath('404'))
    }
  },
  { immediate: true },
)
onMounted(() => {
  title.value = t('errors.page.unknown.title')
  subtitle.value = t('errors.page.unknown.subtitle')
  $log('warn', { title: 'User landed on error page', message: `${title.value}\n${subtitle.value}` })
})
</script>

<template>
  <v-app>
    <LayoutAppBar />
    <v-main>
      <v-container class="pa-6 mb-16" fluid>
        <v-row class="" align="center" justify="center">
          <v-col cols="12" md="1">
            <NuxtImg
              src="/images/bird.svg"
              alt="Error"
              width="120"
            />
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div>
              <p class="font-weight-bold text-h4">
                {{ title }}
              </p>
            </div>
            <div>
              <p class="text-h5">
                {{ subtitle }}
              </p>

              <div v-if="showError">
                <pre v-if="!error?.stack" class="text-body-1 text-pre-wrap" v-text="error" />
                <div v-else class="text-body-1 text-pre-wrap">
                  <dl class="pre-value">
                    <template v-for="(value, name) in error" :key="name">
                      <dt v-text="name" />
                      <dd v-html="value" />
                    </template>
                  </dl>
                </div>
              </div>
              <v-btn-primary
                class="mt-3"
                @click="backToHome"
              >
                {{ $t('errors.page.backToHome') }}
              </v-btn-primary>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <LayoutFooter />
    <!-- <LayoutLoading></LayoutLoading> -->
    <Snackbar />
  </v-app>
</template>

<style lang="scss">
@import '@/assets/style/global.scss';
.pre-value {
  font-family: monospace;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: .4em
}
.pre-value dt {text-align: end}
.pre-value dt::after {content: ':'}
.pre-value pre {
  white-space: pre-wrap !important;
}
</style>
