export function usePriceUtils() {
  function getItemPrice(item: CartItem): number {
    const scalePrices = item.scalePrices && Array.isArray(item.scalePrices) ? item.scalePrices : []

    // Ensure fallback to avoid NaN if amount is missing
    const basePrice = item.listPrice?.amount ?? 0

    if (scalePrices.length === 0) {
      return basePrice
    }

    const applicableScalePrice = scalePrices
      .filter(scale => item.quantity >= scale.minQuantity)
      .sort((a, b) => b.minQuantity - a.minQuantity)[0]

    return applicableScalePrice ? applicableScalePrice.price.amount : basePrice
  }

  // Calculate the total price with scale prices by customer reference
  function calculateTotalByReference(cart: CartV2, referenceTag: string): number {
    return cart.items
      .filter(item => item.reference === referenceTag)
      .reduce((total, item) => total + getItemPrice(item) * item.quantity, 0)
  }

  // Calculate the total price for a whole cart (without reference tags)
  function calculateCartTotal(cart: CartV2): number {
    return cart.items.reduce((total, item) => total + getItemPrice(item) * item.quantity, 0)
  }

  // Calculate freight cost by reference tag
  function getFreightCostByReference(cart: CartV2, referenceTag: string): number {
    const totalSum = calculateTotalByReference(cart, referenceTag)
    const freightAllowance = typeof cart.supplier?.freightAllowance === 'object'
      ? cart.supplier?.freightAllowance?.amount
      : cart.supplier?.freightAllowance || Number.POSITIVE_INFINITY
    const freightCharge = cart.supplier?.freightCharge || 0

    return totalSum >= freightAllowance ? 0 : freightCharge
  }

  // General freight cost calculation
  function getFreightCost(cart: CartV2): number {
    const totalSum = calculateCartTotal(cart)
    const freightAllowance = typeof cart.supplier?.freightAllowance === 'object'
      ? cart.supplier?.freightAllowance?.amount
      : cart.supplier?.freightAllowance || Number.POSITIVE_INFINITY
    const freightCharge = cart.supplier?.freightCharge || 0

    return totalSum >= freightAllowance ? 0 : freightCharge
  }

  return {
    getItemPrice,
    calculateTotalByReference,
    calculateCartTotal,
    getFreightCostByReference,
    getFreightCost,
  }
}
