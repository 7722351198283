export const useCartV2 = defineStore('cart-v2', () => {
  const repository = cartRepository($fetch.create({ baseURL: '/api/easyApi' }))
  const suggestionRepo = suggestionsRepository($fetch.create({ baseURL: '/api/easyApi' }))

  const userStore = useUserStore()
  const supplierStore = useSupplier()

  // utils
  const { getItemPrice } = usePriceUtils()

  // state
  const apiResponse = ref <CartOverview> ({
    combinedSuppliers: [],
    supplierCarts: [],
  })

  const totalAmount = ref(0)
  const supplierSuggestions = ref<Record<string, Suggestion[]>>({})
  const isLoading = ref(false)
  const cartsLoadingState = ref<Record<string, boolean>>({})
  const selectedSupplier = ref<string | null>(null)
  const isReady = ref(false)

  const carts = computed(() => {
    return apiResponse.value.supplierCarts
      .filter(cart => cart.items.length > 0)
      .map((cart) => {
        const isCombined = apiResponse.value.combinedSuppliers.flat().includes(cart.supplier)
        const supplier = supplierStore.suppliers.find(supplier => supplier.handle === cart.supplier)
        const sortedItems = [...cart.items].sort((a, b) =>
          a.handle.localeCompare(b.handle) || a.reference.localeCompare(b.reference),
        )

        const totalSum = cart.items.reduce((total, item) => {
          const itemPrice = getItemPrice(item)
          const itemQuantity = item.quantity || 0
          return total + itemPrice * itemQuantity
        }, 0)

        return {
          ...cart,
          supplier: supplier || {},
          totalSum,
          isCombined,
          suggestions: supplierSuggestions.value[cart.supplier] || [],
          items: sortedItems,
        }
      })
      .sort((a, b) => b.isCombined - a.isCombined)
  })

  const combinedSuppliersCount = computed(() => {
    return carts.value.filter(cart => cart.isCombined).length
  })

  // init cart number just when website loaded for the first time
  async function loadCartCount() {
    if (userStore.data?.handle) {
      const response = await repository.fetchCartItemCount(userStore.data.handle)
      totalAmount.value = response.count
    }
  }

  const isCartLoading = (supplierHandle: string) => {
    return cartsLoadingState.value[supplierHandle] || false
  }

  const initCart = async () => {
    if (isReady.value) {
      return
    }
    await loadCartCount()
    await loadCarts()
    isReady.value = true
  }

  async function loadCarts() {
    if (!userStore.data) {
      return
    }
    isLoading.value = true
    try {
      apiResponse.value = await repository.fetchAllCarts(userStore.data.handle)
      await cartSuggestions()
    }
    finally {
      isLoading.value = false
    }
  }

  async function cartSuggestions() {
    if (!userStore.data) {
      return
    }
    const customerHandle = userStore.data.handle
    const supplierCarts = apiResponse.value.supplierCarts

    for (const cart of supplierCarts) {
      const supplierHandle = cart.supplier
      try {
        const suggestions = await suggestionRepo.fetchSupplierCartSuggestions(customerHandle, supplierHandle)
        supplierSuggestions.value[supplierHandle] = suggestions
      }
      catch (error) {
        console.error(`Failed to fetch suggestions for supplier: ${supplierHandle}`, error)
      }
    }
  }

  async function updateCartMeta(supplierHandle: string, metaInfo: CartMeta) {
    isLoading.value = true
    try {
      apiResponse.value = await repository.updateCartMeta(userStore.data.handle, supplierHandle, metaInfo)
      return true
    }
    catch (err) {
      console.error('Failed to update cart meta:', err)
      return false
    }
    finally {
      isLoading.value = false
    }
  }

  async function updateItem(supplierHandle: string, item: CartItemPut, updateState: boolean = true) {
    cartsLoadingState.value[supplierHandle] = true
    try {
      if (updateState)
        apiResponse.value = await repository.updateCartItem(userStore.data!.handle, supplierHandle, item)
      else await repository.updateCartItem(userStore.data!.handle, supplierHandle, item)
    }
    finally {
      cartsLoadingState.value[supplierHandle] = false
    }
  }

  async function mergeCartItemByReference(supplierHandle: string, itemHandle: string, newReference: string, oldReference: string) {
    cartsLoadingState.value[supplierHandle] = true
    try {
      const currentQuantity = carts.value
        .flatMap(cart => cart.items)
        .filter(item => item.handle === itemHandle)
        .reduce((sum, item) => sum + item.quantity, 0)

      await updateItem(supplierHandle, {
        handle: itemHandle,
        quantity: 0,
        reference: oldReference.toUpperCase(),
      }, false)

      await updateItem(supplierHandle, {
        handle: itemHandle,
        quantity: currentQuantity,
        reference: newReference.toUpperCase(),
      })
    }
    catch (err) {
      console.error('Error occurred while toggling the customer-reference', err)
    }
    finally {
      cartsLoadingState.value[supplierHandle] = false
    }
  }

  async function submitOrder(supplierHandles: string[]) {
    if (!userStore.data?.handle) {
      console.error('Customer handle not found!')
      return null
    }
    isLoading.value = true
    try {
      const response = await repository.orderCarts(userStore.data.handle, supplierHandles)
      if (response?.masterOrderHandle) {
        trackOrderGTM(response.masterOrderHandle, supplierHandles)
        return response
      }
      else {
        console.error('Order submitted but masterOrderHandle is missing:', response)
        return null
      }
    }
    catch (err: any) {
      console.error('Error submitting order:', err)
      return { errors: { unknown: true } }
    }
    finally {
      isLoading.value = false
    }
  }

  // for ga4 tracking
  function trackOrderGTM(masterOrderHandle: string, supplierHandles: string[]) {
    const gtm = useGtm()
    const { calculateCartTotal } = usePriceUtils()
    const { convertProductToGa4EcommerceItem } = useHelper()
    const { $i18n } = useNuxtApp()

    const totalSum = carts.value
      .filter(cart => supplierHandles.includes(cart.supplier.handle))
      .reduce((sum, cart) => sum + calculateCartTotal(cart), 0)

    const ga4Items = carts.value
      .filter(cart => supplierHandles.includes(cart.supplier.handle))
      .flatMap(cart =>
        cart.items.map(item =>
          convertProductToGa4EcommerceItem({
            $i18n,
            data: item,
          }),
        ),
      )

    gtm?.trackEvent({ ecommerce: null })
    gtm?.trackEvent({
      event: 'begin_checkout',
      ecommerce: {
        currency: 'EUR',
        value: totalSum / 100,
        items: ga4Items,
      },
    })

    gtm?.trackEvent({ ecommerce: null })
    gtm?.trackEvent({
      event: 'purchase',
      ecommerce: {
        transaction_id: masterOrderHandle,
        currency: 'EUR',
        value: totalSum / 100,
        items: ga4Items,
      },
    })
  }

  function setSelectedSupplier(handle: string) {
    selectedSupplier.value = handle
  }

  // update totalAmount when items added or removed
  watch(
    () => carts.value,
    (newCarts) => {
      totalAmount.value = newCarts.reduce(
        (sum, cart) => sum + cart.items.reduce(
          (quantity, item) => quantity + item.quantity,
          0,
        ),
        0,
      )
    },
    { immediate: true },
  )

  return {
    isReady,
    apiResponse,
    carts,
    totalAmount,
    combinedSuppliersCount,
    isLoading,
    selectedSupplier,
    initCart,
    loadCartCount,
    loadCarts,
    cartSuggestions,
    updateCartMeta,
    updateItem,
    mergeCartItemByReference,
    submitOrder,
    isCartLoading,
    setSelectedSupplier,
  }
})
