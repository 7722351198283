import { h } from 'vue'

import type { IconAliases, IconProps, IconSet } from 'vuetify'

import IconChevronLeft from '~icons/mdi/chevron-left'
import IconChevronRight from '~icons/mdi/chevron-right'
import IconChevronDown from '~icons/mdi/chevron-down'
import IconChevronUp from '~icons/mdi/chevron-up'
import IconCheckboxBlankOutline from '~icons/mdi/checkbox-blank-outline'
import IconCheckboxMarked from '~icons/mdi/checkbox-marked'
import IconCircle from '~icons/mdi/circle'
import IconCloseCircle from '~icons/mdi/close-circle'
import IconCheck from '~icons/mdi/check'
import IconCheckCircle from '~icons/mdi/check-circle'
import IconClose from '~icons/mdi/close'
import IconInformation from '~icons/mdi/information'
import IconAlertCircle from '~icons/mdi/alert-circle'
import IconMinusBox from '~icons/mdi/minus-box'
import IconArrowUp from '~icons/mdi/arrow-up'
import IconArrowDown from '~icons/mdi/arrow-down'
import IconMenu from '~icons/mdi/menu'
import IconMenuDown from '~icons/mdi/menu-down'
import IconRadioboxMarked from '~icons/mdi/radiobox-marked'
import IconRadioboxBlank from '~icons/mdi/radiobox-blank'
import IconPencil from '~icons/mdi/pencil'
import IconStarOutline from '~icons/mdi/star-outline'
import IconStar from '~icons/mdi/star'
import IconStarHalfFull from '~icons/mdi/star-half-full'
import IconLoading from '~icons/mdi/cached'
import IconPageFirst from '~icons/mdi/page-first'
import IconPageLast from '~icons/mdi/page-last'
import IconUnfoldMoreHorizontal from '~icons/mdi/unfold-more-horizontal'
import IconPaperclip from '~icons/mdi/paperclip'
import IconPlus from '~icons/mdi/plus'
import IconMinus from '~icons/mdi/minus'
import IconCalendar from '~icons/mdi/calendar'
import IconFilter from '~icons/mdi/filter-variant'

// See https://vuetifyjs.com/en/features/icon-fonts/#creating-a-custom-icon-set for more
// See defaults here https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/iconsets/mdi.ts
const aliases: IconAliases = {
  complete: IconCheck,
  cancel: IconCloseCircle,
  close: IconClose,
  delete: IconCloseCircle,
  clear: IconCloseCircle,
  success: IconCheckCircle,
  info: IconInformation,
  warning: IconAlertCircle,
  error: IconCloseCircle,
  prev: IconChevronLeft,
  next: IconChevronRight,
  checkboxOn: IconCheckboxMarked,
  checkboxOff: IconCheckboxBlankOutline,
  checkboxIndeterminate: IconMinusBox,
  delimiter: IconCircle,
  sortAsc: IconArrowUp,
  sortDesc: IconArrowDown,
  expand: IconChevronDown,
  shrink: IconChevronUp,
  menu: IconMenu,
  subgroup: IconMenuDown,
  dropdown: IconMenuDown,
  radioOn: IconRadioboxMarked,
  radioOff: IconRadioboxBlank,
  edit: IconPencil,
  ratingEmpty: IconStarOutline,
  ratingFull: IconStar,
  ratingHalf: IconStarHalfFull,
  loading: IconLoading,
  first: IconPageFirst,
  last: IconPageLast,
  unfold: IconUnfoldMoreHorizontal,
  file: IconPaperclip,
  plus: IconPlus,
  minus: IconMinus,
  calendar: IconCalendar,
  filter: IconFilter,
}

const icons: IconSet = {
  component: (props: IconProps) => h(aliases[props.icon as string]),
}

export { icons, aliases }
