import type { ReturnType } from 'birpc'

export function useHelper() {
  return {
    currencyToString(valueInCent: number) {
      const currencyFormatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      })
      const valueInEuro = valueInCent ? valueInCent / 100 : 0
      return currencyFormatter.format(valueInEuro)
    },
    removeUnderscoresAndCapitalize(text: string) {
      return text
        .replaceAll(/_/g, ' ')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },
    capitalizeFirstLetter(word: string) {
      return word.replace(/^./, word[0].toUpperCase())
    },
    locationQueryToUrlSearchParams(query: Record<string, string | string[]>) {
      const searchParams = new URLSearchParams()
      for (const [key, value] of Object.entries(query)) {
        if (value == null)
          continue
        if (Array.isArray(value)) {
          for (const item of value)
            searchParams.append(key, item)
        }
        else {
          searchParams.append(key, value)
        }
      }
      return searchParams
    },
    abbreviateArray(items: string[]) {
      if (items && Array.isArray(items) && items.length > 3) {
        const appendix = `(+${items.length - 3} weitere)`
        const abbreviated = items.slice(0, 3)
        let returnVal = abbreviated.join('; ')
        returnVal = returnVal.concat(' ', appendix)
        return returnVal
      }

      if (items)
        return items.join('; ')
      return undefined
    },

    // Convert product to ga4 recommended structure
    // See https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=de&client_type=gtm#add_to_wishlist_item for definition
    convertProductToGa4EcommerceItem(
      { index = undefined, data }: { $i18n: ReturnType<typeof useNuxtApp>['$i18n'], index?: number, data: any },
    ) {
      // Extract price safely
      const price = data.listPrice?.amount ? data.listPrice.amount / 100 : undefined

      return {
        item_id: data.handle,
        item_name: data.name || 'Unknown',
        item_brand: data.supplier || '',
        item_category: data.category?.name || 'Unknown',
        price,
        quantity: data.quantity,
        item_variant: data.reference || '',
        discount: 0,
        coupon: undefined,
        index,
      }
    },
  }
}
