import { default as indexo4wIxN0OdsMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/index.vue?macro=true";
import { default as _91id_93ltG5zTKILOMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/[category]/[id].vue?macro=true";
import { default as index8vRVZuhxz7Meta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/[category]/index.vue?macro=true";
import { default as _404HqInw6qIgfMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/404.vue?macro=true";
import { default as profileReXqP6RbRxMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/account/profile.vue?macro=true";
import { default as referencesoqDPbFfUQZMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/account/references.vue?macro=true";
import { default as agbEHdT5bhJWTMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/agb.vue?macro=true";
import { default as boehringerPkmOWGGoE8Meta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/boehringer.vue?macro=true";
import { default as duomyxin8uoVpxRegNMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/duomyxin.vue?macro=true";
import { default as Inuvetn4A2ml1VoAMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/Inuvet.vue?macro=true";
import { default as millpledgefJbHJkhOy2Meta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/millpledge.vue?macro=true";
import { default as remendZDNySR75KvMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/remend.vue?macro=true";
import { default as tessie1P84K2vcaQMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/tessie.vue?macro=true";
import { default as test8qTtG0KEByMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/test.vue?macro=true";
import { default as ziapam0bbXWLfhYMMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/ziapam.vue?macro=true";
import { default as cartwfDdBv9IsgMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/cart.vue?macro=true";
import { default as clinic_45chain4cwmhZl53DMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/clinic-chain.vue?macro=true";
import { default as datasecurityZaGm6VhPsaMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/datasecurity.vue?macro=true";
import { default as forgot_45passwordTNQPhRJorfMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/forgot-password.vue?macro=true";
import { default as gutscheineSeNTWtczebMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/gutscheine.vue?macro=true";
import { default as homengpt1oM3dHMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/home.vue?macro=true";
import { default as imprint56sig6AxpNMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/imprint.vue?macro=true";
import { default as index3AZsuNgoUzMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/index.vue?macro=true";
import { default as profile9VFK5zZ7b9Meta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/internal/profile.vue?macro=true";
import { default as loginmP16tBIF4IMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/login.vue?macro=true";
import { default as _91id_936LuTIX35iZMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/order-confirm/[id].vue?macro=true";
import { default as order_45history_45oldkRj569xNpuMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/order-history-old.vue?macro=true";
import { default as order_45historyubdoDqrHgUMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/order-history.vue?macro=true";
import { default as product_45favoritesiypnHvYSQ6Meta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/product-favorites.vue?macro=true";
import { default as _91id_936N9cyBZ9UtMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/product/[supplier]/[id].vue?macro=true";
import { default as registerMJY87gBHihMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/register.vue?macro=true";
import { default as reset_45password3u9YracQ8mMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/reset-password.vue?macro=true";
import { default as _91id_93pZLtFSwdZDMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/s/orders/[id].vue?macro=true";
import { default as searchm7066SKBtnMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/search.vue?macro=true";
import { default as shopping_45listCPauMkRLJrMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/shopping-list.vue?macro=true";
import { default as _91id_93mxwrhKm0ZiMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/supplier/[id].vue?macro=true";
import { default as suppliersd7A1RVef5UMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/suppliers.vue?macro=true";
import { default as testW8meI28f1TMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/test.vue?macro=true";
import { default as welcomeXs2j8QBlKDMeta } from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/welcome.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexo4wIxN0OdsMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/index.vue")
  },
  {
    name: "country-category-id___de",
    path: "/de/:country()/:category()/:id()",
    meta: _91id_93ltG5zTKILOMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/[category]/[id].vue")
  },
  {
    name: "country-category-id___en",
    path: "/en/:country()/:category()/:id()",
    meta: _91id_93ltG5zTKILOMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/[category]/[id].vue")
  },
  {
    name: "country-category-id___fr",
    path: "/fr/:country()/:category()/:id()",
    meta: _91id_93ltG5zTKILOMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/[category]/[id].vue")
  },
  {
    name: "country-category___de",
    path: "/de/:country()/:category()",
    meta: index8vRVZuhxz7Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/[category]/index.vue")
  },
  {
    name: "country-category___en",
    path: "/en/:country()/:category()",
    meta: index8vRVZuhxz7Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/[category]/index.vue")
  },
  {
    name: "country-category___fr",
    path: "/fr/:country()/:category()",
    meta: index8vRVZuhxz7Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/[category]/index.vue")
  },
  {
    name: "country-404___de",
    path: "/de/:country()/404",
    meta: _404HqInw6qIgfMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/404.vue")
  },
  {
    name: "country-404___en",
    path: "/en/:country()/404",
    meta: _404HqInw6qIgfMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/404.vue")
  },
  {
    name: "country-404___fr",
    path: "/fr/:country()/404",
    meta: _404HqInw6qIgfMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/404.vue")
  },
  {
    name: "country-account-profile___de",
    path: "/de/:country()/account/profile",
    meta: profileReXqP6RbRxMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/account/profile.vue")
  },
  {
    name: "country-account-profile___en",
    path: "/en/:country()/account/profile",
    meta: profileReXqP6RbRxMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/account/profile.vue")
  },
  {
    name: "country-account-profile___fr",
    path: "/fr/:country()/account/profile",
    meta: profileReXqP6RbRxMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/account/profile.vue")
  },
  {
    name: "country-account-references___de",
    path: "/de/:country()/account/references",
    meta: referencesoqDPbFfUQZMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/account/references.vue")
  },
  {
    name: "country-account-references___en",
    path: "/en/:country()/account/references",
    meta: referencesoqDPbFfUQZMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/account/references.vue")
  },
  {
    name: "country-account-references___fr",
    path: "/fr/:country()/account/references",
    meta: referencesoqDPbFfUQZMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/account/references.vue")
  },
  {
    name: "country-agb___de",
    path: "/de/:country()/agb",
    meta: agbEHdT5bhJWTMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/agb.vue")
  },
  {
    name: "country-agb___en",
    path: "/en/:country()/agb",
    meta: agbEHdT5bhJWTMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/agb.vue")
  },
  {
    name: "country-agb___fr",
    path: "/fr/:country()/agb",
    meta: agbEHdT5bhJWTMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/agb.vue")
  },
  {
    name: "country-campaign-boehringer___de",
    path: "/de/:country()/campaign/boehringer",
    meta: boehringerPkmOWGGoE8Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/boehringer.vue")
  },
  {
    name: "country-campaign-boehringer___en",
    path: "/en/:country()/campaign/boehringer",
    meta: boehringerPkmOWGGoE8Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/boehringer.vue")
  },
  {
    name: "country-campaign-boehringer___fr",
    path: "/fr/:country()/campaign/boehringer",
    meta: boehringerPkmOWGGoE8Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/boehringer.vue")
  },
  {
    name: "country-campaign-duomyxin___de",
    path: "/de/:country()/campaign/duomyxin",
    meta: duomyxin8uoVpxRegNMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/duomyxin.vue")
  },
  {
    name: "country-campaign-duomyxin___en",
    path: "/en/:country()/campaign/duomyxin",
    meta: duomyxin8uoVpxRegNMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/duomyxin.vue")
  },
  {
    name: "country-campaign-duomyxin___fr",
    path: "/fr/:country()/campaign/duomyxin",
    meta: duomyxin8uoVpxRegNMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/duomyxin.vue")
  },
  {
    name: "country-campaign-Inuvet___de",
    path: "/de/:country()/campaign/Inuvet",
    meta: Inuvetn4A2ml1VoAMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/Inuvet.vue")
  },
  {
    name: "country-campaign-Inuvet___en",
    path: "/en/:country()/campaign/Inuvet",
    meta: Inuvetn4A2ml1VoAMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/Inuvet.vue")
  },
  {
    name: "country-campaign-Inuvet___fr",
    path: "/fr/:country()/campaign/Inuvet",
    meta: Inuvetn4A2ml1VoAMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/Inuvet.vue")
  },
  {
    name: "country-campaign-millpledge___de",
    path: "/de/:country()/campaign/millpledge",
    meta: millpledgefJbHJkhOy2Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/millpledge.vue")
  },
  {
    name: "country-campaign-millpledge___en",
    path: "/en/:country()/campaign/millpledge",
    meta: millpledgefJbHJkhOy2Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/millpledge.vue")
  },
  {
    name: "country-campaign-millpledge___fr",
    path: "/fr/:country()/campaign/millpledge",
    meta: millpledgefJbHJkhOy2Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/millpledge.vue")
  },
  {
    name: "country-campaign-remend___de",
    path: "/de/:country()/campaign/remend",
    meta: remendZDNySR75KvMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/remend.vue")
  },
  {
    name: "country-campaign-remend___en",
    path: "/en/:country()/campaign/remend",
    meta: remendZDNySR75KvMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/remend.vue")
  },
  {
    name: "country-campaign-remend___fr",
    path: "/fr/:country()/campaign/remend",
    meta: remendZDNySR75KvMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/remend.vue")
  },
  {
    name: "country-campaign-tessie___de",
    path: "/de/:country()/campaign/tessie",
    meta: tessie1P84K2vcaQMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/tessie.vue")
  },
  {
    name: "country-campaign-tessie___en",
    path: "/en/:country()/campaign/tessie",
    meta: tessie1P84K2vcaQMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/tessie.vue")
  },
  {
    name: "country-campaign-tessie___fr",
    path: "/fr/:country()/campaign/tessie",
    meta: tessie1P84K2vcaQMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/tessie.vue")
  },
  {
    name: "country-campaign-test___de",
    path: "/de/:country()/campaign/test",
    meta: test8qTtG0KEByMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/test.vue")
  },
  {
    name: "country-campaign-test___en",
    path: "/en/:country()/campaign/test",
    meta: test8qTtG0KEByMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/test.vue")
  },
  {
    name: "country-campaign-test___fr",
    path: "/fr/:country()/campaign/test",
    meta: test8qTtG0KEByMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/test.vue")
  },
  {
    name: "country-campaign-ziapam___de",
    path: "/de/:country()/campaign/ziapam",
    meta: ziapam0bbXWLfhYMMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/ziapam.vue")
  },
  {
    name: "country-campaign-ziapam___en",
    path: "/en/:country()/campaign/ziapam",
    meta: ziapam0bbXWLfhYMMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/ziapam.vue")
  },
  {
    name: "country-campaign-ziapam___fr",
    path: "/fr/:country()/campaign/ziapam",
    meta: ziapam0bbXWLfhYMMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/campaign/ziapam.vue")
  },
  {
    name: "country-cart___de",
    path: "/de/:country()/cart",
    meta: cartwfDdBv9IsgMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/cart.vue")
  },
  {
    name: "country-cart___en",
    path: "/en/:country()/cart",
    meta: cartwfDdBv9IsgMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/cart.vue")
  },
  {
    name: "country-cart___fr",
    path: "/fr/:country()/cart",
    meta: cartwfDdBv9IsgMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/cart.vue")
  },
  {
    name: "country-clinic-chain___de",
    path: "/de/:country()/clinic-chain",
    meta: clinic_45chain4cwmhZl53DMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/clinic-chain.vue")
  },
  {
    name: "country-clinic-chain___en",
    path: "/en/:country()/clinic-chain",
    meta: clinic_45chain4cwmhZl53DMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/clinic-chain.vue")
  },
  {
    name: "country-clinic-chain___fr",
    path: "/fr/:country()/clinic-chain",
    meta: clinic_45chain4cwmhZl53DMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/clinic-chain.vue")
  },
  {
    name: "country-datasecurity___de",
    path: "/de/:country()/datasecurity",
    meta: datasecurityZaGm6VhPsaMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/datasecurity.vue")
  },
  {
    name: "country-datasecurity___en",
    path: "/en/:country()/datasecurity",
    meta: datasecurityZaGm6VhPsaMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/datasecurity.vue")
  },
  {
    name: "country-datasecurity___fr",
    path: "/fr/:country()/datasecurity",
    meta: datasecurityZaGm6VhPsaMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/datasecurity.vue")
  },
  {
    name: "country-forgot-password___de",
    path: "/de/:country()/forgot-password",
    meta: forgot_45passwordTNQPhRJorfMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/forgot-password.vue")
  },
  {
    name: "country-forgot-password___en",
    path: "/en/:country()/forgot-password",
    meta: forgot_45passwordTNQPhRJorfMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/forgot-password.vue")
  },
  {
    name: "country-forgot-password___fr",
    path: "/fr/:country()/forgot-password",
    meta: forgot_45passwordTNQPhRJorfMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/forgot-password.vue")
  },
  {
    name: "country-gutscheine___de",
    path: "/de/:country()/gutscheine",
    meta: gutscheineSeNTWtczebMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/gutscheine.vue")
  },
  {
    name: "country-gutscheine___en",
    path: "/en/:country()/gutscheine",
    meta: gutscheineSeNTWtczebMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/gutscheine.vue")
  },
  {
    name: "country-gutscheine___fr",
    path: "/fr/:country()/gutscheine",
    meta: gutscheineSeNTWtczebMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/gutscheine.vue")
  },
  {
    name: "country-home___de",
    path: "/de/:country()/home",
    meta: homengpt1oM3dHMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/home.vue")
  },
  {
    name: "country-home___en",
    path: "/en/:country()/home",
    meta: homengpt1oM3dHMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/home.vue")
  },
  {
    name: "country-home___fr",
    path: "/fr/:country()/home",
    meta: homengpt1oM3dHMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/home.vue")
  },
  {
    name: "country-imprint___de",
    path: "/de/:country()/imprint",
    meta: imprint56sig6AxpNMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/imprint.vue")
  },
  {
    name: "country-imprint___en",
    path: "/en/:country()/imprint",
    meta: imprint56sig6AxpNMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/imprint.vue")
  },
  {
    name: "country-imprint___fr",
    path: "/fr/:country()/imprint",
    meta: imprint56sig6AxpNMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/imprint.vue")
  },
  {
    name: "country___de",
    path: "/de/:country()",
    meta: index3AZsuNgoUzMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/index.vue")
  },
  {
    name: "country___en",
    path: "/en/:country()",
    meta: index3AZsuNgoUzMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/index.vue")
  },
  {
    name: "country___fr",
    path: "/fr/:country()",
    meta: index3AZsuNgoUzMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/index.vue")
  },
  {
    name: "country-internal-profile___de",
    path: "/de/:country()/internal/profile",
    meta: profile9VFK5zZ7b9Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/internal/profile.vue")
  },
  {
    name: "country-internal-profile___en",
    path: "/en/:country()/internal/profile",
    meta: profile9VFK5zZ7b9Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/internal/profile.vue")
  },
  {
    name: "country-internal-profile___fr",
    path: "/fr/:country()/internal/profile",
    meta: profile9VFK5zZ7b9Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/internal/profile.vue")
  },
  {
    name: "country-login___de",
    path: "/de/:country()/login",
    meta: loginmP16tBIF4IMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/login.vue")
  },
  {
    name: "country-login___en",
    path: "/en/:country()/login",
    meta: loginmP16tBIF4IMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/login.vue")
  },
  {
    name: "country-login___fr",
    path: "/fr/:country()/login",
    meta: loginmP16tBIF4IMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/login.vue")
  },
  {
    name: "country-order-confirm-id___de",
    path: "/de/:country()/order-confirm/:id()",
    meta: _91id_936LuTIX35iZMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/order-confirm/[id].vue")
  },
  {
    name: "country-order-confirm-id___en",
    path: "/en/:country()/order-confirm/:id()",
    meta: _91id_936LuTIX35iZMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/order-confirm/[id].vue")
  },
  {
    name: "country-order-confirm-id___fr",
    path: "/fr/:country()/order-confirm/:id()",
    meta: _91id_936LuTIX35iZMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/order-confirm/[id].vue")
  },
  {
    name: "country-order-history-old___de",
    path: "/de/:country()/order-history-old",
    meta: order_45history_45oldkRj569xNpuMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/order-history-old.vue")
  },
  {
    name: "country-order-history-old___en",
    path: "/en/:country()/order-history-old",
    meta: order_45history_45oldkRj569xNpuMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/order-history-old.vue")
  },
  {
    name: "country-order-history-old___fr",
    path: "/fr/:country()/order-history-old",
    meta: order_45history_45oldkRj569xNpuMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/order-history-old.vue")
  },
  {
    name: "country-order-history___de",
    path: "/de/:country()/order-history",
    meta: order_45historyubdoDqrHgUMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/order-history.vue")
  },
  {
    name: "country-order-history___en",
    path: "/en/:country()/order-history",
    meta: order_45historyubdoDqrHgUMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/order-history.vue")
  },
  {
    name: "country-order-history___fr",
    path: "/fr/:country()/order-history",
    meta: order_45historyubdoDqrHgUMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/order-history.vue")
  },
  {
    name: "country-product-favorites___de",
    path: "/de/:country()/product-favorites",
    meta: product_45favoritesiypnHvYSQ6Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/product-favorites.vue")
  },
  {
    name: "country-product-favorites___en",
    path: "/en/:country()/product-favorites",
    meta: product_45favoritesiypnHvYSQ6Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/product-favorites.vue")
  },
  {
    name: "country-product-favorites___fr",
    path: "/fr/:country()/product-favorites",
    meta: product_45favoritesiypnHvYSQ6Meta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/product-favorites.vue")
  },
  {
    name: "country-product-supplier-id___de",
    path: "/de/:country()/product/:supplier()/:id()",
    meta: _91id_936N9cyBZ9UtMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/product/[supplier]/[id].vue")
  },
  {
    name: "country-product-supplier-id___en",
    path: "/en/:country()/product/:supplier()/:id()",
    meta: _91id_936N9cyBZ9UtMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/product/[supplier]/[id].vue")
  },
  {
    name: "country-product-supplier-id___fr",
    path: "/fr/:country()/product/:supplier()/:id()",
    meta: _91id_936N9cyBZ9UtMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/product/[supplier]/[id].vue")
  },
  {
    name: "country-register___de",
    path: "/de/:country()/register",
    meta: registerMJY87gBHihMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/register.vue")
  },
  {
    name: "country-register___en",
    path: "/en/:country()/register",
    meta: registerMJY87gBHihMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/register.vue")
  },
  {
    name: "country-register___fr",
    path: "/fr/:country()/register",
    meta: registerMJY87gBHihMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/register.vue")
  },
  {
    name: "country-reset-password___de",
    path: "/de/:country()/reset-password",
    meta: reset_45password3u9YracQ8mMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/reset-password.vue")
  },
  {
    name: "country-reset-password___en",
    path: "/en/:country()/reset-password",
    meta: reset_45password3u9YracQ8mMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/reset-password.vue")
  },
  {
    name: "country-reset-password___fr",
    path: "/fr/:country()/reset-password",
    meta: reset_45password3u9YracQ8mMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/reset-password.vue")
  },
  {
    name: "country-s-orders-id___de",
    path: "/de/:country()/s/orders/:id()",
    meta: _91id_93pZLtFSwdZDMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/s/orders/[id].vue")
  },
  {
    name: "country-s-orders-id___en",
    path: "/en/:country()/s/orders/:id()",
    meta: _91id_93pZLtFSwdZDMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/s/orders/[id].vue")
  },
  {
    name: "country-s-orders-id___fr",
    path: "/fr/:country()/s/orders/:id()",
    meta: _91id_93pZLtFSwdZDMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/s/orders/[id].vue")
  },
  {
    name: "country-search___de",
    path: "/de/:country()/search",
    meta: searchm7066SKBtnMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/search.vue")
  },
  {
    name: "country-search___en",
    path: "/en/:country()/search",
    meta: searchm7066SKBtnMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/search.vue")
  },
  {
    name: "country-search___fr",
    path: "/fr/:country()/search",
    meta: searchm7066SKBtnMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/search.vue")
  },
  {
    name: "country-shopping-list___de",
    path: "/de/:country()/shopping-list",
    meta: shopping_45listCPauMkRLJrMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/shopping-list.vue")
  },
  {
    name: "country-shopping-list___en",
    path: "/en/:country()/shopping-list",
    meta: shopping_45listCPauMkRLJrMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/shopping-list.vue")
  },
  {
    name: "country-shopping-list___fr",
    path: "/fr/:country()/shopping-list",
    meta: shopping_45listCPauMkRLJrMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/shopping-list.vue")
  },
  {
    name: "country-supplier-id___de",
    path: "/de/:country()/supplier/:id()",
    meta: _91id_93mxwrhKm0ZiMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/supplier/[id].vue")
  },
  {
    name: "country-supplier-id___en",
    path: "/en/:country()/supplier/:id()",
    meta: _91id_93mxwrhKm0ZiMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/supplier/[id].vue")
  },
  {
    name: "country-supplier-id___fr",
    path: "/fr/:country()/supplier/:id()",
    meta: _91id_93mxwrhKm0ZiMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/supplier/[id].vue")
  },
  {
    name: "country-suppliers___de",
    path: "/de/:country()/suppliers",
    meta: suppliersd7A1RVef5UMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/suppliers.vue")
  },
  {
    name: "country-suppliers___en",
    path: "/en/:country()/suppliers",
    meta: suppliersd7A1RVef5UMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/suppliers.vue")
  },
  {
    name: "country-suppliers___fr",
    path: "/fr/:country()/suppliers",
    meta: suppliersd7A1RVef5UMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/suppliers.vue")
  },
  {
    name: "country-test___de",
    path: "/de/:country()/test",
    meta: testW8meI28f1TMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/test.vue")
  },
  {
    name: "country-test___en",
    path: "/en/:country()/test",
    meta: testW8meI28f1TMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/test.vue")
  },
  {
    name: "country-test___fr",
    path: "/fr/:country()/test",
    meta: testW8meI28f1TMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/test.vue")
  },
  {
    name: "country-welcome___de",
    path: "/de/:country()/welcome",
    meta: welcomeXs2j8QBlKDMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/welcome.vue")
  },
  {
    name: "country-welcome___en",
    path: "/en/:country()/welcome",
    meta: welcomeXs2j8QBlKDMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/welcome.vue")
  },
  {
    name: "country-welcome___fr",
    path: "/fr/:country()/welcome",
    meta: welcomeXs2j8QBlKDMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/[country]/welcome.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexo4wIxN0OdsMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexo4wIxN0OdsMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexo4wIxN0OdsMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/shop-frontend/pages/index.vue")
  }
]