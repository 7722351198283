import { productFruits } from 'product-fruits'
import type { UserData } from '~/stores/user'

export default defineNuxtPlugin({
  name: 'product-fruits',
  dependsOn: ['pinia'],
  setup(nuxtApp) {
    let alreadyInitiated = false

    const config = useRuntimeConfig()
    const router = useRouter()
    const i18n = nuxtApp.vueApp.config.globalProperties.$i18n
    const locale = i18n.locale
    const { isHeiland, isVetmazing } = useCountry()

    async function init(user?: UserData) {
      if (!user || alreadyInitiated)
        return
      alreadyInitiated = true

      // Todo: Add shop::onboarding::product-fruits feature flag

      // Determine the correct workspace code based on the domain
      const workspaceCode = isHeiland.value
        ? config.public.productFruits.workspaceCodeHeiland
        : isVetmazing.value
          ? config.public.productFruits.workspaceCodeVetmazing
          : ''

      if (workspaceCode) {
        productFruits.init(workspaceCode, locale, {
          username: user.email,
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          signUpAt: new Date(user.registrationDate ?? new Date(2018, 0)).toISOString(),
        })

        router.afterEach(() => {
          productFruits.safeExec(($productFruits) => {
            $productFruits.push(['pageChanged'])
          })
        })
      }
    }

    const userStore = useUserStore()
    userStore.$subscribe((_, { data: user }) => init(user))
    init(userStore.data)
  },
})
