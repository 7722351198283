import type {ThemeDefinition} from "vuetify";
import { heilandColors } from "./colors";
import { heilandVariables } from "./variables";

const heilandTheme: ThemeDefinition = {
  dark: false,
  colors: heilandColors,
  variables: heilandVariables
}

export default heilandTheme
