import type { DefaultsInstance } from "vuetify";
import {heilandColors} from "./colors";

const heilandDefault: DefaultsInstance = {
  VmzBtn : {
    flat: true,
    height: 40,
    class: 'text-none',
    style: `letter-spacing: 0 !important;`,
  },
  VmzBtnTogglePrimary: {
    style: `border-radius: 8px;background-color: ${heilandColors["vm-grey-2"]}`,
    class: 'btn-toggle-primary',
    VmzBtn: {
      color: heilandColors["vm-grey-9"],
      style: `border-radius: 8px;letter-spacing: 0 !important;`,
    }
  },
  VmzBtnToggleSecondary: {
    style: `border-radius: 8px;background-color: ${heilandColors["vm-grey-2"]}`,
    class: 'btn-toggle-secondary',
    VmzBtn: {
      color: heilandColors["vm-grey-9"],
      style: `border-radius: 8px`,
    }
  },
  VmzTextField: {
    style: `border-radius: 8px`,
  },
  VmzCheckbox: {
    style: `color: ${heilandColors["vm-grey-8"]}`,
  }
}

export default heilandDefault
