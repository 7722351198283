import type { ThemeDefinition } from 'vuetify'
import { createVuetify } from 'vuetify'
import {
  VAlert,
  VApp,
  VAppBar,
  VAutocomplete,
  VAvatar,
  VBadge,
  VBanner,
  VBottomNavigation,
  VBottomSheet,
  VBreadcrumbs,
  VBtn,
  VBtnToggle,
  VCard,
  VCarousel,
  VCheckbox,
  VChip,
  VCombobox,
  VDataIterator,
  VDataTable,
  VDatePicker,
  VDialog,
  VDivider,
  VExpansionPanel,
  VFadeTransition,
  VFileInput,
  VFooter,
  VForm,
  VIcon,
  VImg,
  VInput,
  VItemGroup,
  VLabel,
  VLazy,
  VList,
  VMenu,
  VNavigationDrawer,
  VOverlay,
  VPagination,
  VParallax,
  VProgressCircular,
  VProgressLinear,
  VRadioGroup,
  VRangeSlider,
  VRating,
  VResponsive,
  VSelect,
  VSheet,
  VSlider,
  VSnackbar,
  VSpacer,
  VStepper,
  VSwitch,
  VSystemBar,
  VTabs,
  VTextField,
  VTextarea,
  VTimeline,
  VToolbar,
  VTooltip,
  VWindow,
} from 'vuetify/components'

import { defaults, heilandTheme } from '@heiland/heiland-ui-core'
import { aliases, icons } from '../assets/iconsets/iconify'
import '@/assets/style/variables.scss'

const myCustomLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    'primary': '#D21300',
    'secondary': '#4A4A4A',
    'heiland-light-blue': '#4f9efa',
    'heiland-blue': '#4a90e2',
    'heiland-dark-blue': '#305F95',
    'heiland-light-grey': '#E8E8E8',
    'heiland-dark-grey': '#4A4A4A',
    'heiland-light-dark-grey': '#808080',
    'heiland-grey': '#9b9b9b',
    'heiland-dark-green': '#417505',
    'heiland-green': '#4CAF50',
    'heiland-light-green': '#7ED321',
    'heiland-orange': '#F5A623',
    'heiland-yellow': '#FFC107',
    'heiland-purple': '#9013FE',
    'error': '#D21300',
    'success': '#67B40F',
    'pale-grey': '#F8F9FA',
    'background-grey': '#F8F8F8',
    'unfilled-yellow': '#FDFAB3',

    // NEW COLORS
    'white': '#FFFFFF',
    'soft-grey-200': '#E5E4E4',
    'soft-grey-100': '#F0F0EF',
    'grey-500': '#888888',
    'green-300': '#6EB48F',
    'green-500': '#3B815C',
    'green-700': '#2B5E43',
    'red-50': '#FDEEF6',
    'red-200': '#F6B2B6',
    'red-300': '#F08482',
    'red-400': '#EA5153',
    'red-500': '#E73331',
    'red-600': '#B71C1A',
    'pink-surface': '#FDEEF6',
    'grey-surface': '#F4F3F1',
    'black': '#000000',
    'black-800': '#222222',
    'border': '#999',
  },
}

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    // Vue 3 has no way to automatically detect if SSR is used. See https://next.vuetifyjs.com/en/getting-started/installation/#ssr
    ssr: true,
    theme: {
      defaultTheme: 'myCustomLightTheme',
      themes: {
        myCustomLightTheme,
        heilandTheme,
      },
    },
    icons: {
      aliases,
      sets: {
        custom: icons,
        veticon: {
          component: (props: IconProps) => h('span', { class: [`veticon-${props.icon}`] }),
        },
      },
    },
    aliases: {
      VBtnPrimary: VBtn,
      VBtnSecondary: VBtn,
      VBtnTertiary: VBtn,
      VDefaultTextField: VTextField,
      VTextFieldPrimary: VTextField,
      VmzAlert: VAlert,
      VmzApp: VApp,
      VmzAppBar: VAppBar,
      VmzAutocomplete: VAutocomplete,
      VmzAvatar: VAvatar,
      VmzBadge: VBadge,
      VmzBanner: VBanner,
      VmzBottomNavigation: VBottomNavigation,
      VmzBottomSheet: VBottomSheet,
      VmzBreadcrumbs: VBreadcrumbs,
      VmzBtn: VBtn,
      VmzBtnToggle: VBtnToggle,
      VmzBtnTogglePrimary: VBtnToggle,
      VmzCard: VCard,
      VmzCarousel: VCarousel,
      VmzCheckbox: VCheckbox,
      VmzChip: VChip,
      VmzCombobox: VCombobox,
      VmzDataIterator: VDataIterator,
      VmzDataTable: VDataTable,
      VmzDatePicker: VDatePicker,
      VmzDialog: VDialog,
      VmzDivider: VDivider,
      VmzExpansionPanel: VExpansionPanel,
      VmzFileInput: VFileInput,
      VmzFooter: VFooter,
      VmzForm: VForm,
      VmzIcon: VIcon,
      VmzImg: VImg,
      VmzInput: VInput,
      VmzItemGroup: VItemGroup,
      VmzLazy: VLazy,
      VmzList: VList,
      VmzMenu: VMenu,
      VmzNavigationDrawer: VNavigationDrawer,
      VmzOverlay: VOverlay,
      VmzPagination: VPagination,
      VmzParallax: VParallax,
      VmzProgressCircular: VProgressCircular,
      VmzProgressLinear: VProgressLinear,
      VmzRadioGroup: VRadioGroup,
      VmzRangeSlider: VRangeSlider,
      VmzRating: VRating,
      VmzResponsive: VResponsive,
      VmzSelect: VSelect,
      VmzSheet: VSheet,
      VmzSlider: VSlider,
      VmzSnackbar: VSnackbar,
      VmzSpacer: VSpacer,
      VmzStepper: VStepper,
      VmzSwitch: VSwitch,
      VmzSystemBar: VSystemBar,
      VmzTabs: VTabs,
      VmzTextField: VTextField,
      VmzTextarea: VTextarea,
      VmzTimeline: VTimeline,
      VmzToolbar: VToolbar,
      VmzTooltip: VTooltip,
      VmzWindow: VWindow,
    },
    components: {
      VFadeTransition,
      VLabel,
    },
    defaults: {
      VBtn: {
        class: 'text-none',
        flat: true,
        style: 'border-radius: 8px',
      },
      VBtnPrimary: {
        color: 'primary',
        class: 'text-none',
        flat: true,
        size: 'large',
        style: 'border-radius: 8px',
      },
      VBtnSecondary: {
        flat: true,
        class: 'text-none text-primary',
        variant: 'flat',
        color: 'rgba(0, 0, 0, .04)',
        // Same style as vTextField. 0.38 comes from variant outline deselected state
        style: 'border: 1px solid rgba(var(--v-theme-heiland-grey), 0.38)',
        size: 'large',
      },
      VBtnTertiary: {
        class: 'text-none font-weight-bold v-btn-hover',
        variant: 'default',
        color: 'green-500',
        style: 'border-radius: 8px',
        size: 'large',
      },
      VTextField: {
        variant: 'outlined',
        baseColor: 'heiland-grey',
        color: 'heiland-grey',
        density: 'comfortable',
        bgColor: 'rgba(0, 0, 0, .04)',
        style: 'margin-bottom: 2px; border-radius:',
      },
      VTextFieldPrimary: {
        variant: 'solo',
        density: 'comfortable',
        bgColor: 'white',
        style: 'margin-bottom: 2px;',
        rounded: 'lg',
        class: 'v-text-field-primary',
        flat: true,
      },
      VSelect: {
        variant: 'outlined',
        baseColor: 'heiland-grey',
        color: 'heiland-grey',
        density: 'comfortable',
        bgColor: 'rgba(0, 0, 0, .04)',
      },
      VCheckbox: {
        density: 'compact',
        style: 'margin-bottom: 2px; line-height: inherit; font-size: inherit;',
        class: 'text-heiland-dark-grey v-label--no-opacity v-checkbox__v-selection-control__label--font-size-inherit',
        color: 'primary',
      },
      VTextarea: {
        variant: 'outlined',
        baseColor: 'heiland-grey',
        color: 'heiland-grey',
        density: 'comfortable',
        bgColor: 'rgba(0, 0, 0, .04)',
        style: 'margin-bottom: 2px;',
      },
      ...defaults,
    },
  })
  nuxtApp.vueApp.use(vuetify)
})
