import * as Sentry from '@sentry/vue'
import * as pkg from '~/package.json'

export default defineNuxtPlugin((nuxtApp) => {
  const release = `shop-frontend@${pkg.version}`
  const router = useRouter()
  const {
    public: { sentry, environment, i18nDefaultLocale },
  } = useRuntimeConfig()
  if (!sentry.dsn)
    return

  Sentry.init({
    app: nuxtApp.vueApp,
    release,
    environment,
    enabled: environment === 'prod',
    dsn: sentry.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.5, // Change in prod
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      'https://shop-staging.heiland.com',
      'https://shop.staging.vetmazing.com',
      'https://shop.heiland.com',
      'https://shop.vetmazing.com',
    ],
    replaysSessionSampleRate: 0.25,
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary

    ignoreErrors: ['401 Unauthorized', '422 Unprocessable Entity'],
  })

  Sentry.setTag('environment-locale', i18nDefaultLocale as string)

  nuxtApp.vueApp.config.errorHandler = (error: any) => {
    Sentry.captureException(error)
  }

  return {
    provide: {
      sentry: {
        setContext: Sentry.setContext,
        setUser: Sentry.setUser,
        setTag: Sentry.setTag,
        addBreadcrumb: Sentry.addBreadcrumb,
        captureException: Sentry.captureException,
        captureMessage: Sentry.captureMessage,
      },
    },
  }
})
