

export const palette = {
  red: {
    900 : '#B71C1A',
    700 : '#E73331',
    500 : '#EA5153',
    300 : '#F08482',
    100 : '#F6B2B6',
    50 : '#FDEEF6',
  },
  black : '#13141A',
  grey: {
    9 : '#404256',
    8 : '#707489',
    7 : '#8E90A2',
    6 : '#C5C7D2',
    4 : '#D5D7E0',
    3 : '#DFE1E8',
    2 : '#EAEBF1',
    1 : '#F4F5F7',
  },
  white : '#FFFFFF',
  yellow : '#FFA600',
  green : '#00C508',
  purple : '#6E31E8',
  blue : '#316FE8',
}

// color palette: https://www.figma.com/design/LjjM9GwXUzT2NkdAxOKgev/Design-System?node-id=265-970&m=dev
export const heilandColors = {
  'vm-red-900' : palette.red[900],
  'vm-red-700' : palette.red[700],
  'vm-red-500' : palette.red[500],
  'vm-red-300' : palette.red[300],
  'vm-red-100' : palette.red[100],
  'vm-red-50' : palette.red[50],
  'vm-black' : palette.black,
  'vm-grey-9' : palette.grey[9],
  'vm-grey-8' : palette.grey[8],
  'vm-grey-7' : palette.grey[7],
  'vm-grey-6' : palette.grey[6],
  'vm-grey-4' : palette.grey[4],
  'vm-grey-3' : palette.grey[3],
  'vm-grey-2' : palette.grey[2],
  'vm-grey-1' : palette.grey[1],
  'vm-white': palette.white,
  'vm-yellow': palette.yellow,
  'vm-green': palette.green,
  'vm-purple': palette.purple,
  'vm-blue': palette.blue,

  background: palette.grey[3],
  surface: palette.white,
  primary: palette.black,
  secondary: palette.red[700],
  success: palette.green,
  warning: palette.yellow,
  error: palette.red[700],
  info: palette.blue,
}


// 'on-background': '#000',
// 'on-surface': '#000',
// 'on-primary': '#000',
// 'on-secondary': '#000',
// 'on-success': '#00c508',
// 'on-warning': '#ffa600',
// 'on-error': '#000',
// 'on-info': '#000',
