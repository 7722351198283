import type { LoggerBody, LoggerPayload } from '@heiland/logger'
import type { SeverityLevel } from '@sentry/vue'

function sendToSentry($sentry: any, payload: LoggerPayload) {
  let sentryLevel: SeverityLevel = payload.level as SeverityLevel
  switch (payload.level) {
    case 'warn':
      sentryLevel = 'warning'
      break
  }
  $sentry.captureMessage(`${payload.body.message}`, {
    user: { id: payload.body.metadata.customerHandle },
    level: sentryLevel,
    tags: {
      'heiland-trace-id': payload.body.traceId,
      'threadName': payload.body.threadName,
      'device': payload.body.metadata.device,
    },
    extra: payload.body.stack,
  })
}
export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const userStore = useUserStore()

  return {
    provide: {
      log(level: string, body: LoggerBody, slackWebhookUrl = config.public.slackWebhookUrl) {
        const { $sentry } = useNuxtApp()

        let threadName = process.server ? 'server' : 'client'
        if (body.threadName)
          threadName += ` - ${body.threadName}`
        const payload: LoggerPayload = {
          level,
          consoleOnly: true, // TODO may we can remove the slack logger since were using sentry. error.statusCode && error.statusCode < 500,,
          body: {
            ...body,
            threadName,
            metadata: {
              customerHandle: userStore.data?.handle || '',
            },
            stack: body.stack ? JSON.parse(JSON.stringify(body.stack, Object.getOwnPropertyNames(body.stack))) : null,
          },
          slackWebhookUrl,
        }
        useFetch('/api/logger', {
          method: 'POST',
          body: payload,
        })
        if ($sentry)
          sendToSentry($sentry, payload)
      },
      storeErrorLog(filePath: string, internalLoggerMessage: string, snackbarMessage: string, error: any) {
        const { $sentry, $snackbar, $i18n } = useNuxtApp()

        if (error.statusCode === 401)
          $snackbar.showMessage({ text: $i18n.t('snackbar.messages.auth.expired'), color: 'error', closeText: $i18n.t('close') })
        else $snackbar.showMessage({ text: snackbarMessage, color: 'error', closeText: $i18n.t('close') })

        let threadName = process.server ? 'server' : 'client'
        if (filePath)
          threadName += ` - ${filePath}`
        const payload: LoggerPayload = {
          level: 'error',
          consoleOnly: true, // TODO may we can remove the slack logger since were using sentry. error.statusCode && error.statusCode < 500,
          body: {
            title: 'Error in pinia store',
            message: internalLoggerMessage,
            threadName,
            traceId: error.data && error.data.data ? error.data.data.traceId : undefined,
            metadata: {
              customerHandle: userStore.data?.handle || '',
            },
            stack: JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error))),
          },
          slackWebhookUrl: config.public.slackWebhookUrl,
        }

        useFetch('/api/logger', {
          method: 'POST',
          body: payload,
        })
        // We do not want to send 404s and 422s and 401s and 400s to sentry
        if (error.statusCode === 404 || error.statusCode === 422 || error.statusCode === 401 || error.statusCode === 400)
          return
        if ($sentry)
          sendToSentry($sentry, payload)
      },
    },
  }
})
