// Pinia Store
import type { UserAuth } from '~/types'

export interface UserData {
  academicTitle: string | null
  acceptAgb: boolean
  acceptNewsletter: boolean
  billingCity: string | null
  billingPostalCode: string | null
  billingStreetName: string | null
  billingStreetNumber: string | null
  city: string
  clinicChain: {
    handle: string
    name: string
  } | null
  clinicChainMembershipSelfFilled: string
  clinicName: string
  country: string
  dateOfBirth: string | null
  displayClinicChainStatistic: boolean
  email: string
  featureKeys: string[]
  firstname: string
  handle: string
  lastname: string
  officialClinicNumber: string | null
  phone: string
  postalCode: string
  registrationDate: string
  streetName: string
  streetNumber: string
  vatNumber: string | null
  vetDiplomaNumber: string
  vetNarcoticsNumber: null
  vetRegistrationDocument: string | null
  isIbanAvailable?: boolean | null
}

interface ApiError {
  data: {
    code: string
    errors: Record<string, string>
  }
  message: string
  stack: string
  statusCode: number
  statusMessage: string
  url: string
}

interface RegisterFormData {
  clinicName: string
  streetName: string
  streetNumber: string
  postalCode: number
  city: string
  billingStreetName: string
  billingStreetNumber: string
  billingPostalCode: string
  billingCity: string
  clinicChainMembershipSelfFilled: string
  officialClinicNumber: string
  academicTitle: string
  firstname: string
  lastname: string
  vetDiplomaNumber: string
  phone: string
  username: string
  password: string
  countrycode: string
  hearAboutUs?: string
  hearAboutUsText?: string
  acceptAgb: boolean
  acceptNewsletter: boolean
}

interface LoginResponse {
  handle: string
  email: string
  intercomHash: string
  accessToken: string
}

export const useUserStore = defineStore('user', () => {
  const userData = ref<UserData | undefined>(undefined)
  const auth = ref<UserAuth | undefined>(undefined)
  const isAuthenticated = computed(() => !!auth.value)

  function _trackUserEvent(event: string, data?: { email: string, handle: string, intercomHash: string }) {
    const { $intercom } = useNuxtApp()
    const gtm = useGtm()
    gtm?.trackEvent({ event, customer_handle: data?.handle, intercom_user_hash: data?.intercomHash })
    $intercom.update(!data ? {} : { email: data.email, user_id: data.email, user_hash: data.intercomHash })
  }

  function _logError(logMessage: string, error?: any) {
    const { $i18n, $storeErrorLog } = useNuxtApp()
    $storeErrorLog(
      'stores/user.ts',
      logMessage,
      $i18n.t(error.data?.data?.code ? `errors.code.${error.data.data.code}` : 'errors.page.unknown.subtitle'),
      error,
    )
  }

  async function load() {
    const { data, error } = await useFetch<UserAuth>('/api/auth', { retry: 0 })
    // Note that we don't log anything here, since we use this data only to check if the user is logged-in already
    auth.value = !error.value
    && data.value != null
    // under some conditions which I don't understand we receive HTML, so I exclude them from the valid responses
    && !`${data.value}`.startsWith('<!DOCTYPE')
      ? data.value
      : undefined
    return auth.value
  }

  async function login(username: string, password: string) {
    try {
      const loginData = await $fetch<LoginResponse>('/api/login', { method: 'POST', body: { username, password } })
      _trackUserEvent('login', loginData)
      return loginData
    }
    catch (error: any) {
      _logError('Could not login', error)
      return error.data
    }
  }

  async function register(formData: RegisterFormData): Promise<LoginResponse | ApiError> {
    try {
      const registrationData = await $fetch<LoginResponse>('/api/register', { method: 'POST', body: formData })
      _trackUserEvent('sign_up', registrationData)
      return registrationData
    }
    catch (error: any) {
      _logError('Could not register', error)
      return error.data
    }
  }

  async function update(formData: UserData): Promise<{ status: number, error?: ApiError }> {
    try {
      await $fetch('/api/v2/profile', { method: 'PUT', body: formData })
      return {
        status: 200,
      }
    }
    catch (error: any) {
      _logError('Could not update', error)
      return {
        status: error.statusCode,
        error: error.data,
      }
    }
  }
  async function getProfileData() {
    const { data, error } = await useFetch<UserData>('/api/v2/profile', { retry: 0 })
    if (error.value || !data.value) {
      _logError('Could not load profile data', error.value ?? new Error('Failed to get profile-data'))
      return
    }
    userData.value = data.value
    const countryCookie = useCookie('vetmazing-country')
    countryCookie.value = userData.value.country
  }
  async function forgotPassword(username: string): Promise<boolean> {
    try {
      await $fetch('/api/forget-password', { method: 'POST', body: { username } })
      return true
    }
    catch (error: any) {
      // If the status code is not Server Error (a known error like bad request or cognito error like user does not exists) we want the browser to continue
      return error.statusCode < 500
    }
  }
  async function resetPassword(verificationCode: string, username: string, password: string): Promise<any> {
    try {
      return $fetch('/api/reset-password', { method: 'POST', body: { verification_code: verificationCode, username, password } })
    }
    catch (error: any) {
      _logError('Could not reset password', error)
      // If the status code is not Server Error (a known error like bad request or cognito error like user does not exists) we want the browser to continue
      return error.data
    }
  }

  async function logout() {
    try {
      const { $sentry } = useNuxtApp()
      const { countryLocalePath } = useCountry()
      await $fetch(`/api/easyApi/logout/${userData.value?.email}`, { method: 'POST' })
      _trackUserEvent('logout')
      $reset()
      // $sentry is only client side
      if ($sentry)
        $sentry.setUser(null)
      navigateTo(countryLocalePath('/login'), { replace: true })

      /* await resetAllStores() */
    }
    catch (error: any) {
      _logError('Could not logout', error)
    }
  }

  async function updateIban(value: string, consent: boolean): Promise<boolean> {
    try {
      await $fetch<unknown>('/api/v2/profile', { method: 'PUT', body: { iban: value, consent } })
      // If succeed set user data isIbanAvailable to true
      if (userData.value)
        userData.value.isIbanAvailable = true
      return true
    }
    catch (error: any) {
      _logError('Could not update global cart', error)
      return false
    }
  }

  // TODO: This caused snackbar error in logout function
  /*   async function resetAllStores() {
    if (getActivePinia) {
      const activePinia = getActivePinia()
      if (activePinia) {
        Object.entries(activePinia.state.value).forEach(([storeName, state]) => {
          defineStore(storeName, state)(activePinia).$reset()
        })
      }
    }
  } */

  function $reset() {
    userData.value = undefined
    auth.value = undefined
  }

  return {
    isAuthenticated,
    data: userData,
    auth,
    $reset,
    load,
    login,
    register,
    update,
    getProfileData,
    forgotPassword,
    resetPassword,
    logout,
    updateIban,
  }
})
