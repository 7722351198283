import revive_payload_client_YTGlmQWWT5 from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_lCxu0ZmxIx from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5C7QFp7tsq from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_error_handler_os9yNIGLbb from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-vuetify-error-handler/dist/runtime/plugin-error-handler.mjs";
import plugin_snackbar_mPq6rslGGr from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-vuetify-error-handler/dist/runtime/plugin-snackbar.mjs";
import plugin_client_sLfXwu6rIZ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-3-intercom/dist/runtime/plugin.client.mjs";
import _01sentry_client_sP9i2b5gFF from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/plugins/01sentry.client.ts";
import device_PxhEqcDcOg from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/plugins/device.ts";
import easyApi_vIho5F21vn from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/plugins/easyApi.ts";
import gtm_client_OzmBuHLRIb from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/plugins/gtm.client.ts";
import intercom_client_vr7NPky0pK from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/plugins/intercom.client.ts";
import logger_kEXgbufg9p from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/plugins/logger.ts";
import moment_NWWiRYbNqj from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/plugins/moment.ts";
import nuxt_hooks_client_bDXoxecRQ0 from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/plugins/nuxt-hooks.client.ts";
import product_fruits_client_Y4ddJ8LdxR from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/plugins/product-fruits.client.ts";
import vuetify_7h9QAQEssH from "/opt/atlassian/pipelines/agent/build/apps/shop-frontend/plugins/vuetify.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  switch_locale_path_ssr_lCxu0ZmxIx,
  i18n_5C7QFp7tsq,
  plugin_error_handler_os9yNIGLbb,
  plugin_snackbar_mPq6rslGGr,
  plugin_client_sLfXwu6rIZ,
  _01sentry_client_sP9i2b5gFF,
  device_PxhEqcDcOg,
  easyApi_vIho5F21vn,
  gtm_client_OzmBuHLRIb,
  intercom_client_vr7NPky0pK,
  logger_kEXgbufg9p,
  moment_NWWiRYbNqj,
  nuxt_hooks_client_bDXoxecRQ0,
  product_fruits_client_Y4ddJ8LdxR,
  vuetify_7h9QAQEssH
]