<script setup>
const { countryLocalePath } = useCountry()
const display = useDisplay()
</script>

<script>
export default defineComponent({
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    },
  },
})
</script>

<template>
  <v-footer class="footer flex-0-1 bg-vm-grey-3" :class="display.mdAndUp ? 'footer--desktop' : 'footer--mobile'">
    <div>
      <nuxt-link :to="countryLocalePath('/imprint')">
        {{ $t('layout.footer.imprint') }}
      </nuxt-link>
    </div>
    <div>
      <nuxt-link :to="countryLocalePath('/agb')">
        {{ $t('layout.footer.agb') }}
      </nuxt-link>
    </div>
    <div>
      <nuxt-link :to="countryLocalePath('/datasecurity')">
        {{ $t('layout.footer.dataPolicy') }}
      </nuxt-link>
    </div>
  </v-footer>
</template>

<style lang="scss">
.footer{
  padding: 24px;
  > div{
    margin-right: 12px;
  }
  &--mobile{
    margin-bottom: 56px;
  }
  &--desktop{
    //default from navigation drawer https://vuetifyjs.com/en/components/navigation-drawers/#props-width
    padding-left: 56px;
  }
}
</style>
