import type { $Fetch, NitroFetchRequest } from 'nitropack'
import type { Suggestion } from '~/utils/easyApi'

export function suggestionsRepository<T>(fetch: $Fetch<T, NitroFetchRequest>) {
  return {
    /**
     * Retrieve suggestion for the cart of a specific supplier
     */
    async fetchSupplierCartSuggestions(customer: string, supplier: string) {
      return fetch<Suggestion[]>(`/suggestions/cart/${supplier}?customer=${customer}`)
    },
  }
}
