import type { $Fetch, NitroFetchRequest } from 'nitropack'
import type { Supplier, SuppliersPage } from '~/utils/easyApi'

export function supplierRepository<T>(fetch: $Fetch<T, NitroFetchRequest>) {
  return {
    /**
     *  Retrieve suggestion for the cart of a specific supplier
     */
    async fetchSuppliers() {
      return fetch<SuppliersPage | Supplier[]>('/suppliers')
        .then(res => Array.isArray(res) ? res : res.items)
    },
  }
}
