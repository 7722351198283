export default defineNuxtPlugin(() => {
  const { $intercom, $isDesktop } = useNuxtApp()
  const userStore = useUserStore()
  const isDesktop = $isDesktop()
  if (!isDesktop)
    return
  const payload: Record<string, any> = {
    custom_launcher_selector: '#mobile-intercom-launcher',
    hide_default_launcher: !isDesktop,
  }
  if (userStore.data && userStore.auth) {
    payload.email = userStore.data.email
    payload.user_id = userStore.data.email
    payload.user_hash = userStore.auth.intercomHash
  }

  $intercom.boot(payload)
})
