import type { $Fetch, NitroFetchRequest } from 'nitropack'
import type { CartItemPut, CartMeta, CartOverview } from '~/utils/easyApi'

export function cartRepository<T>(fetch: $Fetch<T, NitroFetchRequest>) {
  return {
    /**
     *  Load all carts
     */
    async fetchAllCarts(customer: string) {
      return fetch<CartOverview>(`/cart?customer=${customer}`)
    },

    /**
     *  gets the cart count from the customer
     */
    async fetchCartItemCount(customer: string) {
      return fetch<{ count: number }>(`/cartCount?customer=${customer}`)
    },

    /**
     * Create orders from carts
     * @description This request will place an order, created from the corresponding cart, for each submitted supplier.
     *
     * Implementation note: In case of a successful ordering the request will respond all the remaining carts, or an
     * empty list if all carts ordered.
     */
    async orderCarts(customer: string, suppliers: string[]) {
      return fetch<CartOverview>(`/cart?customer=${customer}`, { method: 'POST', body: suppliers })
    },

    /**
     *  Update cart meta-information
     */
    async updateCartMeta(customer: string, supplier: string, meta: CartMeta) {
      return fetch<CartOverview>(`/cart/${supplier}/meta?customer=${customer}`, { method: 'PUT', body: meta })
    },

    /**
     * Update an item of a cart
     * @description Implementation note: This request creates, updates and deletes items in the cart!
     *
     * - If the submitted item doesn't exist in the cart it will be created;
     * - If the submitted item already exists, it will override the existing item;
     * - If an amount of zero is submitted the item will be removed from the cart;
     */
    async updateCartItem(customer: string, supplier: string, item: CartItemPut) {
      return fetch<CartOverview>(`/cart/${supplier}/items?customer=${customer}`, { method: 'PUT', body: item })
    },
  }
}
