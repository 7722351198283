// noinspection JSUnusedGlobalSymbols
export default defineNuxtPlugin(() => {
  const easyApi = $fetch.create({
    baseURL: '/api/easyApi',
  })
  return {
    provide: {
      easyApi,
    },
  }
})
