<script setup lang="ts">
import IconCalendar from '~icons/mdi/calendar'
import IconCart from '~icons/mdi/cart'

const cartStore = useCartV2()
const userStore = useUserStore()
const { country, countryLocalePath } = useCountry()
const config = useRuntimeConfig()
const display = useDisplay()
const logoURL = computed(() => {
  return country.value === 'DE' ? '/logos/heiland_logo_light.svg' : '/logos/vetmazing_lightred.svg'
})

const userHasCalendarFeatureKey = ['pms.general', 'pms.calendar'].every(key => userStore.data?.featureKeys.includes(key)) ?? false
</script>

<template>
  <v-sheet v-if="display.width.value === 0" class="app-bar" />
  <v-app-bar flat color="red-400" class="app-bar" :height="display.width.value !== 0 && display.mdAndUp.value ? '80' : '120'">
    <NuxtImg class="app-bar__bgImg img-left" src="/images/bird.svg" width="188" />
    <NuxtImg class="app-bar__bgImg img-right" src="/images/bird.svg" width="148" />

    <v-container class="container-fluid-mobile">
      <v-row no-gutters align="center">
        <v-col
          cols="6"
          sm="6"
          :md="userHasCalendarFeatureKey ? '1' : '2'"
          lg="2"
        >
          <NuxtLink :to="countryLocalePath('/')">
            <v-badge
              v-if="config.public.environment !== 'prod'"
              :content="config.public.environment"
              :color="config.public.environment === 'dev' ? 'heiland-orange' : 'heiland-light-blue'"
            >
              <nuxt-picture v-if="logoURL" :src="logoURL" width="138" height="30" alt="Logo" />
            </v-badge>
            <nuxt-picture v-else-if="logoURL" :src="logoURL" width="138" height="30" alt="Logo" />
          </NuxtLink>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          :md="userHasCalendarFeatureKey ? '7' : '8'"
          order="12"
          order-sm="12"
          order-md="2"
          test-id="search-wrapper"
        >
          <LayoutSearch v-if="userStore.isAuthenticated" />
        </v-col>
        <v-col
          v-if="userStore.isAuthenticated"
          cols="6"
          sm="6"
          :md="userHasCalendarFeatureKey ? '4' : '2'"
          :lg="userHasCalendarFeatureKey ? '3' : '2'"
          order="6"
          order-sm="6"
          order-md="10"
          class="d-flex justify-end"
        >
          <div v-if="userHasCalendarFeatureKey" class="pl-1">
            <v-btn color="white" :href="`${config.public.pmsUrl}/calendar`" size="large">
              <span class="text-white mr-1">{{ $t('layout.accountMenu.appointment') }}</span>
              <v-icon :icon="IconCalendar" />
            </v-btn>
          </div>
          <div>
            <v-badge v-if="cartStore.totalAmount" :max="99" :content="cartStore.totalAmount" color="black-800" offset-x="5" offset-y="8">
              <v-btn color="white" :to="countryLocalePath('/cart')" size="large">
                <span class="text-white mr-1">{{ $t('cart.title') }}</span>
                <v-icon :icon="IconCart" />
              </v-btn>
            </v-badge>
            <v-btn v-else color="white" :to="countryLocalePath('/cart')" size="large">
              <span class="text-white mr-1">{{ $t('cart.title') }}</span>
              <v-icon :icon="IconCart" />
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<style lang="scss">
.app-bar {
  height: 80px !important;
  > div {
    height: 80px !important;
  }
  @media (max-width: 960px) {
    height: 120px !important;
    > div {
      height: 120px !important;
    }
  }
  &__bgImg {
    position: absolute;
    z-index: -1;
    &.img-left {
      left: -50px;
      top: -50px;
    }
    &.img-right {
      right: 50px;
      top: 50px;
    }
  }
  @media (max-width: 600px) {
    &__bgImg {
      display: none !important;
    }
  }
   &__search-wrapper {
    width: 720px;
  }
}
.container-fluid-mobile {
  @media (max-width: 1280px) {
    max-width: 100%;
  }
}
</style>
