export function useCountry() {
  const route = useRequestURL()
  const userStore = useUserStore()
  const localePath = useLocalePath()

  const country = computed(() => {
    // there are no german users on vetmazing.com
    // BUT early users from france have DE set as their country due to legacy reasons…
    if (route.origin.endsWith('vetmazing.com') && userStore.data?.country === 'DE')
      return 'FR'
    return userStore.data?.country ?? 'DE' // germany is our very default country
  })
  const countryLanguage = computed(() => country.value.toLowerCase())
  const isHeiland = computed(() => route.origin.endsWith('heiland.com'))
  const isVetmazing = computed(() => route.origin.endsWith('vetmazing.com'))
  const countryByGuess = computed(() => route.origin.endsWith('vetmazing.com') ? 'FR' : 'DE')
  const countryByPathname = computed(() => route.pathname.replace(/^\/[a-zA-Z]{2}\/([a-zA-Z]{2})(?:\/.*|$)/, '$1'))
  const countryLocalePath = (route: Parameters<typeof localePath>[0], locale?: Parameters<typeof localePath>[1]) => {
    const path = localePath(route, locale) || localePath(`/${route}`, locale)
    return userStore.data?.country
      ? path.replace(/^\/(\w+)/, `/$1/${country.value.replace(/.*[-/]/, '').toLowerCase()}`)
      : path.replace(/^\/(\w+)/, `/$1/${countryByGuess.value.toLowerCase()}`)
  }
  const globalPath = (path: string) => path.replace(/^\/([a-zA-Z]{2}\/){0,2}/, '/')

  return {
    country,
    countryLanguage,
    isHeiland,
    isVetmazing,
    countryByGuess,
    countryByPathname,
    countryLocalePath,
    globalPath,
  }
}
