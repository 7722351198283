import type { Supplier } from '~/types'

export const useSupplier = defineStore('supplier', () => {
  const repository = supplierRepository($fetch.create({ baseURL: '/api/easyApi' }))
  const userStore = useUserStore()
  const featureSettingsStore = useFeatureSettingsStore()
  featureSettingsStore.loadSettings()
  // state
  const apiResponse = ref <Supplier[]> ([])
  const isLoading = ref<boolean>(false)

  async function loadSuppliers() {
    try {
      isLoading.value = true
      apiResponse.value = await repository.fetchSuppliers()
    }
    finally {
      isLoading.value = false
    }
  }

  const suppliers = computed(() => {
    return apiResponse.value.filter((s) => {
      const clinicChainHandle = userStore.data?.clinicChain?.handle || 'none'
      return !featureSettingsStore.clinicChain[clinicChainHandle]?.exclude?.suppliers?.includes(s.handle)
    }).map(s => ({
      ...s,
      freightAllowance: !Number.isNaN(Number(s.freightAllowance)) ? Number(s.freightAllowance) : s.freightAllowance.amount,
      freightCharge: !Number.isNaN(Number(s.freightCharge)) ? Number(s.freightCharge) : s.freightCharge.amount,
      discountNewCustomer: s.discountNewCustomer,
      isVatIdRequired: s.isVatIdRequired,
      possibleServiceItems: s.possibleServiceItems ?? s.offeredServiceItems.map(item => item.handle),
      imageUrl: s.imageUrl ?? s.image,
      minCartPrice: s.minCartPrice,
      locale: '',
    }))
  })

  function sendProductNotFoundEmail(supplierHandle: string, feedbackText: string) {
    (async function () {
      const { $snackbar, $i18n } = useNuxtApp()
      try {
        isLoading.value = true
        await $fetch('/api/v2/emails/product-feedback', {
          method: 'POST',
          body: { supplierHandle, feedbackText },
        })
        $snackbar.showMessage({ text: $i18n.t('searchResult.productNotFoundDialog.success'), color: 'success', closeText: $i18n.t('close') })
        isLoading.value = false
      }
      catch (error) {
        isLoading.value = false
        console.error(`Could not send product not found mail to supplier:`, error)
      }
    })()
  }

  function $reset() {
    apiResponse.value = []
    isLoading.value = false
  }

  return {
    suppliers,
    isLoading,
    $reset,
    loadSuppliers,
    sendProductNotFoundEmail,
  }
})
